import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "./../components/Container"
import Wysiwyg from "./../components/Wysiwyg"
import { InnerContainer } from "./../components/InnerContainer"
import AccordionList from "../components/Accordion/AccordionList"

import { introductionWysiwyg } from "./../mocks/introductionWysiwyg"
import { introductionAccordion } from "./../mocks/introductionAccordion"
import { introductionWysiwygAbout } from "./../mocks/introductionWysiwygAbout"

const IntroductionPage = () => (
  <Layout>
    <SEO
      title="The only brand index focused on business-to-business brands"
      description="Gone are the days where brand is only perceived to be essential for organisations that have consumer products. From our perspective, measuring B2B to B2C brands (as many other indexes do) is not comparing apples to apples."
    />
    <Container bg="white">
      <InnerContainer>
        <Container maxWidth="680px" pb={["12px", "32px", "36px"]}>
          <Wysiwyg>
            <div dangerouslySetInnerHTML={{ __html: introductionWysiwyg }} />
          </Wysiwyg>
        </Container>
        <Container overflow="hidden" pb={["12px", "32px", "46px"]}>
          <AccordionList
            heading="Meet the panel"
            items={introductionAccordion}
          />
        </Container>
        <Container maxWidth="680px" pb={"10px"}>
          <Wysiwyg>
            <div
              dangerouslySetInnerHTML={{ __html: introductionWysiwygAbout }}
            />
          </Wysiwyg>
        </Container>
      </InnerContainer>
    </Container>
  </Layout>
)

export default IntroductionPage
