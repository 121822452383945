import MorrisonPhoto from "./../assets/images/sheila-photo.jpg"
import JuliePhoto from "./../assets/images/julie-photo.jpg"
import JustinPhoto from "./../assets/images/justin-photo.jpg"
import JonPhoto from "./../assets/images/jon-photo.jpg"
import MarkPhoto from "./../assets/images/mark-photo.jpg"
import MichalPhoto from "./../assets/images/michal-photo.jpg"
import StephenPhoto from "./../assets/images/stephen-photo.jpg"

export const introductionAccordion = [
  {
    id: 0,
    title: "Sheila Morrison, Brand & Culture Director",
    content:
      "<p>Sheila graduated with a BBA in Marketing and an MA in Advertising and Brand Planning from the University of Texas at Austin. She has over 13 years’ experience working at UK and US brand agencies, including SuperUnion, FutureBrand and Ogilvy & Mather NYC, and now leads our Brand & Culture strategy team. Sheila’s expertise lies in helping corporate organisations define who they are and what they stand for. She has extensive experience in defining and articulating brand platforms, brand architecture frameworks, brand positioning and personality as well as corporate purpose and values.</p>",
    image: {
      altText: "Sheila Morrison photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: MorrisonPhoto,
        srcSet: MorrisonPhoto,
        srcSetWebp: MorrisonPhoto,
        srcWebp: MorrisonPhoto,
      },
    },
    icon: "",
  },
  {
    id: 1,
    title: "Julie Sim, Brand Strategist",
    content:
      "<p>Julie graduated with a 2:1 in BSc (Hons) Psychology from Royal Holloway, University of London. After a spell at FutureBrand, she joined our brand strategy team in 2019. Julie uses her strong analytical skills to develop and guide brand positioning, corporate narratives and employee engagement campaigns. She works across a wide range of sectors, from technology and professional services to financial services and commercial real estate.</p>",
    image: {
      altText: "Julie Sim photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: JuliePhoto,
        srcSet: JuliePhoto,
        srcSetWebp: JuliePhoto,
        srcWebp: JuliePhoto,
      },
    },
    icon: "",
  },
  {
    id: 2,
    title: "Mark Litchfield, Executive Creative Director",
    content:
      "<p>Mark graduated with a BA (Hons) in Fine Art and a BA (Hons) in Art History. He has over 15 years’ experience working for leading London agencies across a broad range of brand communications. Mark brings a wealth of expertise in how to create best-in-class brand experiences. He oversees our team of creative talents, inspiring them in delivering effective and relevant solutions.</p>",
    image: {
      altText: "Mark photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: MarkPhoto,
        srcSet: MarkPhoto,
        srcSetWebp: MarkPhoto,
        srcWebp: MarkPhoto,
      },
    },
    icon: "",
  },
  {
    id: 3,
    title: "Jon Towell, Creative Partner",
    content:
      "<p>As co-founder of Luminous, Jon has driven our best-in-class reputation for creative effectiveness in the corporate space. He graduated with a First Class Honours degree in Graphic Communications and brings vast experience in brand communications to the table. His work has received widespread recognition, with awards from industry bodies including D&AD, Design Week and CorpComms. Jon specialises in helping clients articulate brand stories that connect with key influencers, working with the creative team to ensure they bring those ideas to life in inspiring ways.</p>",
    image: {
      altText: "Jon Towell Photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: JonPhoto,
        srcSet: JonPhoto,
        srcSetWebp: JonPhoto,
        srcWebp: JonPhoto,
      },
    },
    icon: "",
  },
  {
    id: 4,
    title: "Michal Kendereski, Digital Director",
    content:
      "<p>Michal is a member of the Industry Capacity Board for the European Commission ARETE project, addressing innovation with interactive technologies, undertaken by University College Dublin (UCD). He has extensive agency experience, having founded Republik Media and served as the Director of Innovation at Volume. Michal is responsible for developing our distinctive digital offering. His rich skill-set enables us to approach digital from a holistic perspective, combining the best of B2C and B2B with new immersive technologies.</p>",
    image: {
      altText: "Michal Kendereski Photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: MichalPhoto,
        srcSet: MichalPhoto,
        srcSetWebp: MichalPhoto,
        srcWebp: MichalPhoto,
      },
    },
    icon: "",
  },
  {
    id: 5,
    title: "Stephen Butler, Investor Engagement & ESG Director",
    content:
      "<p>Stephen holds a BA (Hons) from Central Saint Martins and a Certificate in Investor Relations (CIR) from the IR Society. He also has a Postgraduate Certificate in Corporate Governance and Business Ethics from the University of London. He leads a team of strategists specialising in helping corporate businesses engage effectively with the investor community.</p>",
    image: {
      altText: "Stephen Butler Photo",
      fluid: {
        aspectRatio: 1.6,
        sizes: "(max-width: 316px) 100vw, 316px",
        src: StephenPhoto,
        srcSet: StephenPhoto,
        srcSetWebp: StephenPhoto,
        srcWebp: StephenPhoto,
      },
    },
    icon: "",
  },
]
